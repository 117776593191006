import React from "react";
import AdminLayout from "../../components/admin/adminLayout";
import Dashboard from "../../components/admin/dashboard";
import SEO from "../../components/seo";
import WithPrivateRoute from "../../components/admin/withPrivateRoute";

const Admin = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `dashboard`] }
        title = "Dashboard"
        isAdmin = { true }
      />
      <Dashboard />
    </AdminLayout>
  );
};

export default WithPrivateRoute(Admin);