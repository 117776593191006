import React from "react";
import dashboardIllustration from "../../images/dashboard-illustration.svg";

const Dashboard = () => (
  <section className="text-center">
    <img
      alt = "Dashboard"
      className = "block mx-auto w-1/2"
      src = { dashboardIllustration }
    />
    <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
      Dashboard incoming...
    </h2>
  </section>
);

export default Dashboard;